(function ($) {
    window.lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy",
        threshold: 100,
        effect : "fadeIn"
    });

    function refresh_size_queries() {
        var classes = [];
        var scrollbarwidth = getScrollbarWidth();
        window_width = $(window).width() + scrollbarwidth;
        window_height = $(window).height();
        is_less_phone = (window_width <= 414);
        is_phone = (window_width <= 768);
        is_mobile = (window_width <= 992);
        is_tablet_portrait = (window_width > 768 && window_width < 992);
        is_tablet_landscape = (window_width >= 992 && window_width < 1200 && window_height <= 768);
        is_tablet = is_tablet_portrait || is_tablet_landscape;
        is_desktop = (window_width >= 1200);
        is_desktop_large = (window_width >= 1367);
        is_less_desktop_large = (window_width <= 1366);
        is_desktop_xxl = (window_width >= 1700 && window_height >= 800);

        if (is_less_phone) {
            classes.push('mq_less_phone');
        }
        if (is_phone) {
            classes.push('mq_phone');
        }
        if (is_mobile) {
            classes.push('mq_mobile');
        }
        if (is_tablet_portrait) {
            classes.push('mq_tablet_portrait');
        }
        if (is_tablet_landscape) {
            classes.push('mq_tablet_landscape');
        }
        if (is_tablet) {
            classes.push('mq_tablet');
        }
        if (is_desktop) {
            classes.push('mq_desktop');
        }
        if (is_desktop_large) {
            classes.push('mq_desktop_large');
        }
        if (is_desktop_xxl) {
            classes.push('mq_desktop_xxl');
        }


        $('html').removeClass('mq_less_phone');
        $('html').removeClass('mq_phone');
        $('html').removeClass('mq_mobile');
        $('html').removeClass('mq_tablet_portrait');
        $('html').removeClass('mq_tablet_landscape');
        $('html').removeClass('mq_tablet');
        $('html').removeClass('mq_desktop');
        $('html').removeClass('mq_desktop_xxl');

        $('html').addClass(classes.join(' '));

    }

    function getScrollbarWidth() {
        var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
        document.body.appendChild(outer);
        var widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";
        // add innerdiv
        var inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);
        var widthWithScroll = inner.offsetWidth;
        // remove divs
        outer.parentNode.removeChild(outer);
        return widthNoScroll - widthWithScroll;
    }

    function headerPosition() {
        var header = $('#header');
        var headerHeight = $('#top_bar').height();
        var scroll = $(window).scrollTop();

        if (scroll > headerHeight) {
            header.addClass('fixed');
        } else {
            header.removeClass('fixed');
        }
    }

    function la_cigale_skip_to_content(){
        $('.skip-to-content').on('click keypress', function(e){
            var offset =  0;
            offset = $('#header').height() + 50;
            $('html, body').animate({
                scrollTop: $('main .the_title').offset().top - offset
            }, 500);

            $('main a').focus();
        });
    }

    function la_cigale_language_manager_on_focus() {
        //This function is for accessibility
        document.addEventListener("keydown", function(event) {
            if(event.which == 9){

                //language selector
                $('#lang_selector').focus(function () {
                    $('#lang_selector').keyup(function (e) {
                        if (e.which == 13) {
                            $('#languages_wrap .close_popup').focus();
                        }
                    });
                    $('.lang_list').find('li:last-child a').focusout(function () {
                        $('#languages_wrap .close_popup').focus();
                        $('#languages_wrap .close_popup').keyup(function (e) {
                            if (e.which == 13) {
                                $('#lang_selector').focus();
                            }
                        });
                    });
                });

                //sidebar menu
                $('#open_menu').focus(function () {
                    var close_sidebar = $('#menu_sidebar_wrap .close_sidebar');
                    $('#open_menu').keyup(function (e) {
                        if (e.which == 13) {
                            close_sidebar.focus();
                        }
                    });
                    $('#menu_sidebar_wrap a:last').focusout(function () {
                        close_sidebar.focus();
                        close_sidebar.keyup(function (e) {
                            if (e.which == 13) {
                                $('#open_menu').focus();
                            }
                        });
                    });
                });

                $('#primary_menu_sidebar a').focus(function () {
                    $(this).next().css({
                        'display': 'block',
                    });
                });

                //bookingform popup
                $('#main_book, .open_bookingform').focus(function () {
                    var close_sidebar = $('#open_bookingform .close_popup');
                    $('#main_book').keyup(function (e) {
                        if (e.which == 13) {
                            close_sidebar.focus();
                        }
                    });
                });
                $('.bookingform input[type=submit]').focusout(function(){
                    $('#open_bookingform .close_popup').focus();
                });
                $('#open_contact_form input[type=submit]').focusout(function(){
                    $('#open_contact_form .close_popup').focus();
                });


                //last element in the footer, return to the first element in the DOM
                $('footer a:last').focus(function () {
                    $(this).focusout(function(){
                        $('.skip-to-content').focus();
                    });
                });

            } else if(event.which == 27){
                if($('.room_wrap, .codes_wrap').hasClass('visible')){
                    // $('.close_bookingform_dropdown').hide();
                    $('.room_wrap, .codes_wrap').removeClass('visible').css('height','0');
                }
            }
        });


    }


    function la_cigale_sidebarmenu() {
        var link = $('#open_menu');
        var wrap = $('#menu_sidebar_wrap');
        var close = wrap.find('.close_sidebar');

        if (link.length > 0 && wrap.length > 0) {

            var submenus = wrap.find('.menu-item-has-children');
            submenus.each(function(){
                $(this).append('<span class="dropdown"></span>');
            });
            submenus.find('.dropdown').click(function () {
                $(this).toggleClass('rotate');
                $(this).prev().slideToggle();
            });

            link.on('keypress', function(){
                wrap.find('.current-menu-item a').focus();
            });

            link.on('click', function(){
                setTimeout(function () {
                    wrap.addClass('visible');
                }, 300);
                $('html, body').css('overflow', 'hidden');
                $('#menu_sidebar_wrap .close_sidebar').focus();
            });

            close.on('click', function () {
                wrap.removeClass('visible');
            });

        }
    }

     function isotope_filters(selector) {
         var container = $(selector);

         // filter items on button click
         $('.filter_container').on('click', 'a', function () {
             var filterValue = $(this).attr('data-category');


             if($('body').hasClass('page-template-template-dinners') || $('body').hasClass('page-template-template-location')){
                 container = $('#repeater-list .children-wrap, '+ selector);
                 if(filterValue !== '.all-items'){
                     $('div[data-first]').removeClass('first_highlight');
                 } else {
                     $('div[data-first]').addClass('first_highlight');
                 }
                 $('#children-list .single-child-wrap').removeAttr('data-aos').removeAttr('data-aos-duration').removeAttr('data-aos-delay');
             }

             var isOriginLeft = (! la_cigale_rtl);

             if($('body').hasClass('page-template-template-gallery')){
                 container.isotope({
                     filter: filterValue,
                     layoutMode: 'fitRows',
                     itemSelector: '.single-image-wrap',
                     transitionDuration: '0.8s',
                     originLeft: isOriginLeft,
                     fitRows: {
                         gutter: 1
                     }
                 });
             } else {

                 container.isotope({
                     filter: filterValue,
                     layoutMode: 'fitRows',
                     originLeft: isOriginLeft,
                 });
             }


             $('.filter_container a').removeClass('active');
             $(this).toggleClass('active');

             AOS.refresh();
         });

         //filter items on select
         $('#select_filter').change(function () {
             container.isotope({filter: this.value});

             AOS.refresh();
         });
     }


     function la_cigale_minigallery_init(){
         var minigallery = $('.minigallery');
         var minigalleryWrapper = $('#minigallery_wrapper');
         if(minigalleryWrapper.length === 0) return false;

         if( minigallery.find('.slide').size() > 3  || (is_phone && minigallery.find('.slide').size() > 1)) {
             var prev_next_btn = (minigallery.find('.slide').size() > 3 || is_phone)? true : false;

             minigallery.on('init', function(event, slick){
                 lazyLoadInstance.update();

             });

             minigallery.slick({
                 rows: 0,
                 centerMode: prev_next_btn,
                 slidesToShow: 3,
                 dots: prev_next_btn,
                 arrows: prev_next_btn,
                 infinite: true,
                 variableWidth: false,
                 speed: 1000,
                 rtl: (la_cigale_rtl === 1)? true : false,
                 lazyLoad: 'progressive',
                 nextArrow: $('.container_minigallery').find('.next'),
                 prevArrow: $('.container_minigallery').find('.prev'),
                 accessibility: true,
                 responsive: [
                     {
                         breakpoint: 993,
                         settings: {
                             slidesToShow: 2,
                             centerMode: true,
                             arrows: true,
                             infinite:true,
                             dots: false,
                         }
                     },
                     {
                         breakpoint: 769,
                         settings: {
                             slidesToShow: 1,
                             centerMode: true,
                             arrows: true,
                             infinite:true,
                             dots: false,
                             variableWidth: true,
                         }
                     },
                 ]
             });
         }
    }


    function la_cigale_staggered_boxes_carousel(container, item_for_slides, numberSlides){
        var wrap = $(container);
        var numElements = wrap.find(item_for_slides).length;
        var arrows;
        if (wrap.length === 0) return false;

        console.log(numElements)
        number_slidesEvent = numberSlides;
        arrows = (numElements > numberSlides) ? true : false;

        if(is_less_desktop_large ) {
            number_slidesEvent = (number_slidesEvent > 1) ? 2 : 1;
            arrows = (numElements > number_slidesEvent) ? true : false;
        }
        if(is_mobile) {
            number_slidesEvent = 1;
            arrows = (numElements > 1) ? true : false;
        }

        if(numElements > number_slidesEvent){

            wrap.on('init', function(event, slick){
                lazyLoadInstance.update();
                wrap.find(item_for_slides +'.slick-active[data-slick-index="1"]').addClass("space_top");
            });
            wrap.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                wrap.find(item_for_slides +'[data-slick-index="'+ nextSlide +'"]').removeClass("space_top");
                wrap.find(item_for_slides +'[data-slick-index="'+ (nextSlide+1) +'"]').addClass("space_top");
            });

            var adapt_height = (is_less_phone);

            // go wrap
            wrap.slick({
                slidesToShow: number_slidesEvent,
                rows: 0,
                variableWidth: true,
                centerMode: false,
                infinite: true,
                adaptiveHeight: adapt_height,
                dots: false,
                rtl: (la_cigale_rtl === 1)? true : false,
                speed: 1200,
                arrows: arrows,
                lazyLoad: 'progressive',
                nextArrow: wrap.parent().find('.next'),
                prevArrow: wrap.parent().find('.prev'),
            });
        }

    }


    function la_cigale_children_carousel_init(selector , wrapper){
        var boxes = $(selector + ':not(.slick-initialized)');
        var boxesWrapper = $(wrapper);

        if(boxesWrapper.length === 0) return false;

        var condition;
        if(!is_phone){
            condition = (boxes.find('.single-child-wrap').size() > 3);
        } else {
            condition = (boxes.find('.single-child-wrap').size() > 1);
        }
        var breckP = 991;

        // if($('body').hasClass('single-post_meeting_rooms') && $(window).width() < 828 && $(window).width() > 768){
        //     condition = (boxes.find('.single-child-wrap').size() > 2);
        //     breckP = 828;
        // }

        if(condition) {
            boxes.on('init', function (event, slick) {
                lazyLoadInstance.update();

                setTimeout(function(){
                    boxes.find('a.title-link').each(function(){
                        $(this).attr('tabindex','-1');
                    });
                }, 300);
            });

            boxes.on('afterChange', function (event, slick, currentSlide, nextSlide){
                setTimeout(function(){
                    boxes.find('a.title-link').each(function(){
                        $(this).attr('tabindex','-1');
                    })
                }, 300);
            });

            boxes.slick({
                rows: 0,
                centerMode: true,
                slidesToShow: 3,
                dots: true,
                arrows: true,
                infinite: true,
                variableWidth: false,
                lazyLoad: 'progressive',
                speed: 1200,
                rtl: (la_cigale_rtl === 1)? true : false,
                nextArrow: boxesWrapper.find('.next'),
                prevArrow: boxesWrapper.find('.prev'),
                accessibility: true,
                responsive: [{
                        breakpoint: 1282,
                        settings: {
                            slidesToShow: 2,
                    }},{
                        breakpoint: 993,
                        settings: {
                            slidesToShow: 1,
                            dots: false,
                    }},
                ]
            });
        }
    }


    function la_cigale_scaled_carousel_init(selector , wrapper){
        var boxes = $(selector + ':not(.slick-initialized)');
        var boxesWrapper = $(wrapper);

        if(boxesWrapper.length === 0) return false;

        var condition = (boxes.find('.single-child-wrap').size() > 1);

        if(condition) {
            boxes.on('init', function (event, slick) {
                lazyLoadInstance.update();

                setTimeout(function(){
                    boxes.find('a.title-link').each(function(){
                        $(this).attr('tabindex','-1');
                    });
                }, 300);
            });

            boxes.on('afterChange', function (event, slick, currentSlide, nextSlide){
                setTimeout(function(){
                    boxes.find('a.title-link').each(function(){
                        $(this).attr('tabindex','-1');
                    })
                }, 300);
            });

            boxes.slick({
                rows: 0,
                centerMode: true,
                slidesToShow: 1,
                dots: false,
                arrows: true,
                infinite: true,
                variableWidth: true,
                lazyLoad: 'progressive',
                speed: 1200,
                rtl: (la_cigale_rtl === 1)? true : false,
                nextArrow: boxesWrapper.find('.next'),
                prevArrow: boxesWrapper.find('.prev'),
                accessibility: true,
            });
        }
    }


    function la_cigale_wellness_carousel_init(selector, wrapper, single_child){
        var boxes = $(selector + ':not(.slick-initialized)');
        if(single_child == null) single_child = '.single-child-wrap' ;


        var boxesWrapper = $(wrapper);
        if(boxesWrapper.length === 0 || boxesWrapper.find(single_child).length <= 1) return false;

        var dots = (! is_mobile);
        boxes.on('init', function (event, slick) {
            lazyLoadInstance.update();

            setTimeout(function(){
                boxes.find('a.title-link').each(function(){
                    $(this).attr('tabindex','-1');
                });
            }, 300);
        });

        boxes.on('afterChange', function (event, slick, currentSlide, nextSlide){
            setTimeout(function(){
                boxes.find('a.title-link').each(function(){
                    $(this).attr('tabindex','-1');
                })
            }, 300);
        });

        boxes.slick({
            rows: 0,
            centerMode: true,
            slidesToShow: 1,
            dots: dots,
            arrows: true,
            infinite: true,
            variableWidth: false,
            lazyLoad: 'progressive',
            speed: 1200,
            rtl: (la_cigale_rtl === 1)? true : false,
            nextArrow: boxesWrapper.find('.next'),
            prevArrow: boxesWrapper.find('.prev'),
            accessibility: true,
        });
    }



    function la_cigale_meeting_table_select() {
        var select_capacity = $('#select_capacity');
        if(select_capacity.length === 0) return false;

        var init_value = select_capacity.val();
        $('.flex-table .flex-row.'+init_value).css({display: "flex"});

        select_capacity.change(function(){
            var changed_val = $(this).val();
            $('.flex-table .flex-row:not(.first)').hide();

            $('.flex-table .flex-row.'+changed_val).css({display: "flex"});

        })
    }

    function la_cigale_adjust_table_rows(){
        if(is_phone){
            $('.flex-table .flex-row:not(.first)').css('width', '45vw');
        } else {
            var total_capacities = $('.flex-table.header.hide_mobile .flex-row:not(.first)').length;
            var w_total = $('#meetings_table').width();
            var w_first_row = $('.flex-table.header.hide_mobile .flex-row.first').outerWidth(true); //padding & margin
            if(total_capacities > 0){
                var $num_max_rows = (total_capacities >= 12)? 12 : total_capacities;
                var $width_single_item = (w_total - w_first_row) / $num_max_rows;

                $('.flex-table .flex-row:not(.first)').css('width', $width_single_item);
            }
        }
    }



    function google_map() {
        // starting up map template-part
        if (($('#map_box').length > 0)) {

            var container_map = $('.container_map');
            // travelmode selection
            var button_itinerary_mode = $('.itinerary_mode');
            button_itinerary_mode.click(function (e) {
                e.stopPropagation();
                // this
                var anchor = $(this);
                // check
                if (anchor.hasClass('active')) return false;
                // reset
                $('.itinerary_mode.active').removeClass('active');
                // adding class
                anchor.addClass('active');
            });

            // routing
            $('#calculate').on('click', function () {
                calcRoute();
            });

            //this part handle the close_map button
            $('.close_map').click(function () {
                $('#map_directions').fadeOut(function () {
                    directionsDisplay.setPanel(null);
                    directionsDisplay.setMap(null);
                    map.setZoom(12);
                    map.setCenter(new google.maps.LatLng(gmap_config.hotel_info.latitude, gmap_config.hotel_info.longitude));

                });
                $('.itineraryPanelWrapper').remove();
            });
        }

    };



    function openPopup() {
        var btn = $('.open_popup');
        btn.each(function(){
            var this_popup = $(this).attr('href');
            var overlay = $(this_popup);
            var close = $(this_popup).find('.close_popup');

            $(this).click(function () {
                overlay.fadeIn('fast', function () {
                    setTimeout(function(){

                        centerOverlay(this_popup +' .box_in_overlay');
                        overlay.addClass('visible');
                        // overlay.focus();
                    }, 150)
                });
                $('html').addClass('no_scroll');
            });
            close.click(function () {
                overlay.fadeOut();
                overlay.removeClass('visible');
                $('html').removeClass('no_scroll');
            });
        });


        $(document).click(function (event) {
            var closeme = true;
            if ($(event.target).closest(btn).length > 0) {
                closeme = false;
            }
        });

    }

    function openBookingform() {
        var btn = $('#main_book, .open_bookingform');
        var overlay = $('#open_bookingform');
        var close = $('#open_bookingform').find('.close');

        btn.click(function () {
            overlay.fadeIn('fast', function () {
                setTimeout(function(){
                    centerOverlay('#open_bookingform .box_in_overlay');
                    overlay.addClass('visible');
                    $('#numberOfRooms').val('1').trigger('change');
                }, 150)
            });
            $('html').addClass('no_scroll');

        });
        close.click(function () {
            overlay.fadeOut();
            overlay.removeClass('visible');
            $('html').removeClass('no_scroll');
        });


        $(document).click(function (event) {
            var closeme = true;
            if ($(event.target).closest(btn).length > 0) {
                closeme = false;
            }
        });

    }

    function openSidebraMenu() {
        var btn = $('#open_menu');
        var overlay = $('#menu_sidebar_wrap');
        var close = $('#menu_sidebar_wrap').find('.close_sidebar');

        var submenus = overlay.find('.menu-item-has-children');
        submenus.each(function(){
            $(this).append('<span class="dropdown"></span>');
        });
        submenus.find('.dropdown').click(function () {
            $(this).toggleClass('rotate');
            $(this).prev().slideToggle();
        });

        btn.on('keypress', function(){
            overlay.find('.current-menu-item a').focus();
        });

        btn.click(function () {
            overlay.fadeIn('fast', function () {
                setTimeout(function(){
                    overlay.addClass('visible');
                    $('#menu_sidebar_wrap .close_sidebar').focus();
                }, 200)
            });
            $('html').addClass('no_scroll');

        });
        close.click(function () {
            overlay.fadeOut();
            overlay.removeClass('visible');
            $('html').removeClass('no_scroll');
        });


        $(document).click(function (event) {
            var closeme = true;
            if ($(event.target).closest(btn).length > 0) {
                closeme = false;
            }
        });

    }


    function centerOverlay(id) {
        var element = $(id);

        if (!is_phone) {
            var windowH = $(window).height();
            var elementH = element.outerHeight();
            var margin = 20;
            if (elementH < windowH) {
                margin = (windowH - elementH) / 2;
            }
            element.css({
                'margin-top': margin,
                'margin-bottom': margin,
            })
        }
    }

    function la_cigale_opencloseviewmore() {
        var button_readmore = $('.view_more_content');

        button_readmore.on('click tap', function (e) {
            e.stopPropagation();
            // this
            var anchor = $(this);
            var div_id = anchor.data('id');
            // id optional
            var more_text_box = (typeof div_id !== 'undefined') ? $('#' + div_id) : $(this).parent().find('.more_text_box');
            // reset
            if (typeof div_id == 'undefined') {
                var more_text_box_opened = $('.more_text_box.opened');
                if (more_text_box_opened.length > 0) {
                    more_text_box_opened.stop().animate({height: 0}, 300, function () {
                        more_text_box_opened.parent().find('.view_more_content').removeClass('opened');
                        more_text_box_opened.removeClass('opened');
                    });
                }
            }
            if ($(this).hasClass('opened')) {
                more_text_box.stop().animate({height: 0}, 300, function () {
                    anchor.removeClass('opened');
                    more_text_box.removeClass('opened');
                });
            } else {
                var customHeight = more_text_box.find('.more_text').outerHeight(true);
                more_text_box.stop().animate({height: customHeight}, 300, function () {
                    anchor.addClass('opened');
                    more_text_box.addClass('opened');
                });
            }
            //close on btn
            more_text_box.find('.close').on('click tap', function () {
                more_text_box.stop().animate({height: 0}, 300, function () {
                    anchor.removeClass('opened');
                    more_text_box.removeClass('opened');
                });
            });
        });
        // var button_popup = $('.view_pop_up');
        // button_popup.click(function (e) {
        //     e.stopPropagation();
        //     var html = button_popup.closest('.popup_content').find('.more_text_box').html();
        //     $.fancybox.open(html);
        // });
    }


    function la_cigale_handler_map(){
        var home_map_loaded = false;
        if ($('#map_box').length === 0) return false;

        var position_map = $('#location').offset().top;
        $(window).on("scroll", function() {
            var scrollPosition = window.pageYOffset;
            if(!home_map_loaded && scrollPosition >= position_map){
                home_map_loaded = true;

                la_cigale_InitMap('map_box', gmap_config);
                google_map();
            }
        });
    }


    // initilize carousel for tripadvisor comments
    function la_cigale_tripadvisor_carousel(){
        var tripadvisor = $('.c-ta-reviews');
        var tripadvisorWrapper = $('#tripadvisor_feedbacks');
        if(tripadvisorWrapper.length === 0) return false;

        var prev_next_btn = (la_cigale_tripadvisor === 2)? true : false;
        var slide_to_show = la_cigale_tripadvisor;

        slide_to_show = (is_mobile)? 1 : slide_to_show;

        tripadvisor.slick({
            rows: 0,
            slidesToShow: slide_to_show,
            arrows: prev_next_btn,
            infinite: false,
            rtl: (la_cigale_rtl === 1)? true : false,
            lazyLoad: 'progressive',
            prevArrow: '<a href="javascript:void(0);" class="arrow prev slick-prev" aria-label="'+ previous_image_text + '"></a>',
            nextArrow: '<a href="javascript:;" class="arrow next slick-next" aria-label="'+ next_image_text + '"></a>',
            accessibility: true,
        });
    }


    function openLanguages() {
        var btn = $('#lang_selector, .lang_selector.mobile_selector');
        var overlay = $('#languages_wrap');
        var close = overlay.find('.close_popup');

        btn.click(function () {

            overlay.fadeIn('fast', function () {
                centerOverlay('#languages');
                overlay.addClass('visible');
                // overlay.focus();
            });
            $('html').addClass('no_scroll');
        });
        close.click(function () {
            overlay.fadeOut();
            overlay.removeClass('visible');
            $('html').removeClass('no_scroll');
        });

        $(document).click(function (event) {
            var closeme = true;

            if ($(event.target).closest(btn).length > 0) {
                closeme = false;
            }
            if ($(event.target).closest(overlay.find('#languages')).length > 0) {
                closeme = false;
            }
            if (closeme) {
                if (overlay.hasClass('visible')) {
                    overlay.removeClass('visible');
                    overlay.fadeOut();
                    $('html').removeClass('no_scroll');
                }
            }
        });
    }


    // initilize carousel for tripadvisor comments
    function la_cigale_tripadvisor_carousel(){
        var tripadvisor = $('#tripadvisor_feedbacks ul');
        var tripadvisorWrapper = $('#tripadvisor_feedbacks');
        if(tripadvisorWrapper.length === 0) return false;

        tripadvisor.slick({
            rows: 0,
            slidesToShow: 1,
            arrows: false,
            fade: true,
            speed: 1000,
            cssEase: 'ease-in-out',
            infinite: false,
            autoplay: true,
            autoplaySpeed: 5000,
            rtl: (la_cigale_rtl === 1)? true : false,
            lazyLoad: 'progressive',
            accessibility: true,
        });
    }



    $(document).ready(function () {
        refresh_size_queries();
        headerPosition();
        la_cigale_opencloseviewmore();
        la_cigale_minigallery_init();
        openPopup();
        openBookingform();
        openSidebraMenu();
        openLanguages();

        //aos animations
        AOS.init({
            startEvent: 'load',
        });

        //footer socials animation delay
        var k = 0;
        $( ".footer_social .aos_social" ).each(function( ) {
            $(this).attr('data-aos-delay', k);
            k += 300;
        });

        la_cigale_skip_to_content();
        la_cigale_language_manager_on_focus();

        //accessibility aria-expanded
        $( ".language_selector" ).hover(
            function() {
                if($('#other_languages:visible').length > 0){
                    $('.current_language').attr('aria-expanded', 'true');
                } else {
                    $('.current_language').attr('aria-expanded', 'false');
                }
            }, function() {
                $('.current_language').attr('aria-expanded', 'false');
            }
        );

        if($('body').hasClass('home')){
            la_cigale_wellness_carousel_init('.preview_dining_carousel', '#homepage_dining .container_carousel_arrows');

            la_cigale_handler_map();
        }

        if($('body').hasClass('page-template-template-rooms')){
            isotope_filters('#grid_layout .children-wrap');
        }
        if($('body').hasClass('single-post_rooms') ){
            la_cigale_children_carousel_init('#children-list .do_carousel', '#children-list');
        }
        if($('body').hasClass('single-post_dinners') ){
            if(!is_mobile){
                la_cigale_children_carousel_init('#offers_preview .here_carousel_offers', '#offers_preview');
            } else {
                la_cigale_scaled_carousel_init('#offers_preview .here_carousel_offers', '#offers_preview');
            }
        }
        if($('body').hasClass('page-template-template-wellness_offers') ){

            if(!is_mobile){
                la_cigale_children_carousel_init('#offers_preview .here_carousel_offers', '#offers_preview');
            } else {
                la_cigale_scaled_carousel_init('#offers_preview .here_carousel_offers', '#offers_preview');
            }

        }
        if($('body').hasClass('page-template-template-wellness')){
            la_cigale_wellness_carousel_init('.preview_wellness_carousel', '.container_carousel_arrows');
        }
        if($('body').hasClass('page-template-template-dinners')){
            isotope_filters('#children-list .children-wrap');
        }
        if($('body').hasClass('page-template-template-meetings')){
            isotope_filters('.filterable_meetings');
        }
        if($('body').hasClass('page-template-template-gallery')){
            isotope_filters('.photo-gallery-container');

            $('.filter_wrapper a[data-category=".all-items"]').trigger('click');
        }


        if($('body').hasClass('page-template-template-offers')){

            for(var i = 0; i < $('div[id^="offers_preview_"]').length; i++){
                isotope_filters('.container_offers_type');


                if(!is_mobile){
                    la_cigale_staggered_boxes_carousel('#offers_preview_'+ i +' .here_carousel_offers', '.single-child-wrap', 3);
                } else {
                    la_cigale_scaled_carousel_init('#offers_preview_'+ i +' .here_carousel_offers', '#offers_preview_'+ i );
                }

            }
        }

        if($('body').hasClass('page-template-template-meetings')) {
            la_cigale_adjust_table_rows();
            if(is_phone) {
                la_cigale_meeting_table_select();
            } else {
                $('.flex-table .flex-row').css({display: "flex"});
            }
        }

        if($('body').hasClass('page-template-template-location')){

            la_cigale_InitMap('map_box', gmap_config);
            google_map();


            isotope_filters('#children-list .children-wrap');
        }

        //fake newsletter submit nutton click the real button -> go to newsletter page
        $('#fake_submit_newsletter').click(function(){
            $('#newsletter_form input[type="submit"]').trigger('click');
        });

        //repositioning popup on bookingform change room
        var bookingform = $('.bookingform');
        var room_selector = bookingform.find('.rooms_select');
        room_selector.change(function () {
            setTimeout(function () {
                centerOverlay('#open_bookingform .box_in_overlay');
            }, 300);
        });
    });

    $(window).scroll(function () {
        headerPosition();

    });

    $(window).on('load', function () {

        if($('body').hasClass('home')){
            for(var i = 0; i < $('div[id^="rooms_preview_"]').length; i++){
                la_cigale_wellness_carousel_init('#rooms_preview_'+ i +' .here_carousel_rooms', '#rooms_preview_'+ i +' .container_carousel_arrows');
            }

            $('#homepage_rooms').find('.filter_container a').click(function(){
                var filter = $(this).data('category');
                $('div[id^="rooms_preview_"]').css('height', '0').css('z-index', '-1');
                $('#homepage_rooms '+ filter).css('height', 'auto').css('z-index', '1');

                $('.filter_container a').removeClass('active');
                $(this).toggleClass('active');

            })

            $('#homepage_rooms #select_filter').change(function(){
                var filter = $(this).val();
                $('div[id^="rooms_preview_"]').css('height', '0').css('z-index', '-1');
                $('#homepage_rooms '+ filter).css('height', 'auto').css('z-index', '1');

            })

            $('#homepage_rooms a[data-category=".all-items"]').trigger('click');


            la_cigale_wellness_carousel_init('.preview_wellness_carousel', '#homepage_wellness .container_carousel_arrows');
            la_cigale_wellness_carousel_init('.preview_meetings_carousel', '#homepage_meetings .container_carousel_arrows','.container_boxes');


            if(!is_mobile){
                la_cigale_staggered_boxes_carousel('#offers_preview .here_carousel_offers', '.single-child-wrap', 3);

                // la_cigale_children_carousel_init('#offers_preview .here_carousel_offers', '#offers_preview');
            } else {
                la_cigale_scaled_carousel_init('#offers_preview .here_carousel_offers', '#offers_preview');
            }

            la_cigale_tripadvisor_carousel()
        }



        //form listen
        var attachement = $('input[name="quotation_need_attachment"]');
        var rooms_guests = $('input[name="quotation_event_required_rooms"]');

        var prev = null;
        for (var i = 0; i < rooms_guests.length; i++) {
            rooms_guests[i].addEventListener('change', function () {
                if (this !== prev) {
                    prev = this;
                }
                if(this.value === 'Yes'){
                    $('input[name="quotation_number_rooms_event"]').attr('required','required');
                } else {
                    $('input[name="quotation_number_rooms_event"]').attr('required',false);
                }
            });
        }
        var prev_att = null;
        for (var i = 0; i < attachement.length; i++) {
            attachement[i].addEventListener('change', function () {
                if (this !== prev_att) {
                    prev_att = this;
                }
                if(this.value === 'Yes'){
                    $('input[name="quotation_attachment"]').attr('required','required');
                } else {
                    $('input[name="quotation_attachment"]').attr('required',false);
                }
            });
        }

    });

    $(window).resize(function () {
        refresh_size_queries();

        if($('body').hasClass('page-template-template-meetings')) {
            la_cigale_adjust_table_rows();
            if(is_phone) {
                la_cigale_meeting_table_select();
            } else {
                $('.flex-table .flex-row').css({display: "flex"});
            }
        }
    });


})(jQuery);